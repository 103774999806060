<template>
  <b-modal :id="id" :title="name" ok-only ok-title="Schließen" size="xl" content-class="mt-5" scrollable>
    <b-row cols="4">
      <b-col v-for="site in siteList" :key="site.id">
        <b-list-group class="mb-2">
          <b-list-group-item :class="style(site)" v-bind="links(site)">
            {{ site.site.displayname }}
            <span class="text-right">{{ count(site) }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "SiteModal",
  props: ['id', 'name', 'type', 'siteList'],
  methods: {
    style(site) {
      let x = ['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size', 'mb-2']
      if(this.type !== 'hostnameRedundant' && this.type !== 'longOffline') {
        this.count(site) > 0 ? x.push('list-danger', 'text-white') : x.push('list-success', 'text-white')
      }
      else {
        this.count(site) > 0 ? x.push('list-warning') : x.push('list-success', 'text-white')
      }
      return x
    },
    count(site) {
      switch(this.type) {
        case 'virus':
          return site.virus_count;
        case 'license':
          return site.license_warning_count;
        case 'process':
          return site.process_count;
        case 'longOffline':
          return site.pc_offline_long.length;
        case 'hostnameRedundant':
          return site.hostname_redundant.length;
      }
    },
    links(site) {
      switch(this.type) {
        case 'virus':
          return {
            to: { name: 'VirusList', query: {site: site.site.shortname}},
            target: "_blank"
          }
        case 'license':
          return {
            to: { name: 'Licenses', query: {site: site.site.shortname}},
            target: "_blank"
          }
        case 'process':
          return {
            to: { name: 'ProcessList', query: {site: site.site.shortname}},
            target: "_blank"
          }
        case 'longOffline':
          return {
            to: { name: 'ComputerList', query: {site: site.site.shortname}},
            target: "_blank"
          }
        case 'hostnameRedundant':
          return {
            to: { name: 'ComputerList', query: {site: site.site.shortname}},
            target: "_blank"
          }
      }
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/Dashboard';
</style>

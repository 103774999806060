<template>
  <b-card header-bg-variant="alfa-blue" header="News" header-class="text-center header-font-size">
    <b-card-body class="scroll">
      <NewsSingle v-for="news in newsAll" :key="news.id" :news="news" class="mb-2"/>
    </b-card-body>
  </b-card>
</template>

<script>
import NewsSingle from "@/components/news/NewsSingle";

export default {
  name: "NewsInfo",
  props: ['newsAll'],
  components: {
    NewsSingle
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Dashboard.scss';

.scroll {
  max-height: 20rem;
  overflow-y: auto;
}
</style>

<template>
  <div>
    <b-row>
      <b-col>
        <DashboardHeader class="mt-3 pb-2 pt-4" :dashboardInfo="dashboardInfo" :timestamp="timestamp"/>
      </b-col>
      <b-col>
        <NewsInfo v-if="dashboardInfo" class="mt-3" :newsAll="dashboardInfo.news"/>
      </b-col>
    </b-row>
    <b-card-group columns class="mt-4">
      <SiteInfo v-if="dashboardInfo" :siteData="dashboardInfo.site" @openPcModal="openModal"/>
      <TeamInfo v-if="dashboardInfo" :teamData="dashboardInfo.team"/>
      <RpcInfo v-if="dashboardInfo" :rpcData="dashboardInfo.rpc" @openPcModal="openModal"/>
    </b-card-group>
    <PcModal :title="modalTitle" :pcList="modalData"/>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import RpcInfo from "@/components/dashboard/RpcInfo";
import NewsInfo from "@/components/dashboard/NewsInfo";
import TeamInfo from "@/components/dashboard/TeamInfo";
import SiteInfo from "@/components/dashboard/SiteInfo";
import PcModal from "@/components/dashboard/dashboardModal/PcModal";
import DashboardHeader from "@/components/dashboard/DashboardHeader";

export default {
  name: 'Home',
  components: {
    PcModal,
    RpcInfo,
    TeamInfo,
    NewsInfo,
    SiteInfo,
    DashboardHeader
  },
  data() {
    return {
      modalTitle: '',
      modalData: null,
      dashboardInfoTimer: null,
      dashboardInfo: null,
      timestamp: dayjs()
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    openModal(data) {
      this.modalTitle = data.title
      this.modalData = data.pcList
      this.$bvModal.show('pcModal')
    },
    async getDashboardInfo() {
      await getRequest('dashboard', null, this, 'Es ist ein Fehler beim Aktualisieren der Daten aufgetreten.')
          .then((response) => {
            this.dashboardInfo = response.data;
            this.timestamp = dayjs();
          })
    }
  },
  mounted() {
    this.setLoadingState(false)
    this.getDashboardInfo()
    this.dashboardInfoTimer = setInterval(() => {
      this.getDashboardInfo()
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.dashboardInfoTimer)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/Dashboard.scss';
</style>

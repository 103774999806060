var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"header-bg-variant":"alfa-blue","header":"Standort","header-class":"text-center header-font-size"}},[_c('b-row',{attrs:{"cols":"2"}},[_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center list-font-size mb-2 list-default"},[_vm._v(" Rechner insgesamt "),_c('span',[_vm._v(_vm._s(_vm.siteData.pc_count))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.siteData.license_warning_count > 0 ? 'list-danger' : 'list-success',
                                    'text-white', 'mb-2'],attrs:{"to":{ name: 'Licenses', query: {site: _vm.siteData.site.shortname}}}},[_vm._v(" Lizenzwarnungen "),_c('span',[_vm._v(_vm._s(_vm.siteData.license_warning_count))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center list-font-size mb-2 list-default"},[_vm._v(" Rechner online "),_c('span',[_vm._v(_vm._s(_vm.siteData.pc_count_online))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.siteData.process_count > 0 ? 'list-danger' : 'list-success',
                                    'text-white', 'mb-2'],attrs:{"to":{ name: 'ProcessList', query: {site: _vm.siteData.site.shortname}}}},[_vm._v(" Prozesswarnungen "),_c('span',[_vm._v(_vm._s(_vm.siteData.process_count))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size', 'mb-2',
                                    _vm.siteData.pc_offline_long.length > 0 ? ['list-warning', 'clickable'] : 'list-success'],on:{"click":function($event){return _vm.onModalShow(_vm.siteData.pc_offline_long.length)}}},[_vm._v(" Rechner lange offline "),_c('span',[_vm._v(_vm._s(_vm.siteData.pc_offline_long.length))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.siteData.virus_count > 0 ? 'list-danger' : 'list-success',
                                    'text-white', 'mb-2'],attrs:{"to":{ name: 'VirusList', query: {site: _vm.siteData.site.shortname}}}},[_vm._v(" Virenwarnungen "),_c('span',[_vm._v(_vm._s(_vm.siteData.virus_count))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"header-bg-variant":"alfa-blue","header-class":"text-center header-font-size"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("RPCs "),_c('b-icon-info-circle',{attrs:{"scale":"0.8","id":"tt-card-header-rpc"}})],1),_c('b-tooltip',{attrs:{"target":"tt-card-header-rpc"}},[_vm._v("Zugewiesene RPCs, von Kunden aus dem eigenen Team")])]},proxy:true}])},[_c('b-row',{attrs:{"cols":"2"}},[_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center list-font-size mb-2 list-default"},[_vm._v(" Rechner insgesamt "),_c('span',[_vm._v(_vm._s(_vm.rpcData.pc_count))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.rpcData.pc_license_warning.length > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                    'text-white', 'mb-2'],on:{"click":function($event){return _vm.onModalShow(_vm.rpcData.pc_license_warning.length, 'Lizenzwarnungen', _vm.rpcData.pc_license_warning)}}},[_vm._v(" Lizenzwarnungen "),_c('span',[_vm._v(_vm._s(_vm.rpcData.pc_license_warning.length))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center list-font-size mb-2 list-default"},[_vm._v(" Rechner online "),_c('span',[_vm._v(_vm._s(_vm.rpcData.pc_count_online))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.rpcData.process_count > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                    'text-white', 'mb-2'],on:{"click":function($event){return _vm.onModalShow(_vm.rpcData.process_count, 'Prozesswarnungen', _vm.rpcData.pc_processes)}}},[_vm._v(" Prozesswarnungen "),_c('span',[_vm._v(_vm._s(_vm.rpcData.process_count))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size', 'mb-2',
                                    _vm.rpcData.pc_offline_long.length > 0 ? ['list-warning', 'clickable'] : 'list-success'],on:{"click":function($event){return _vm.onModalShow(_vm.rpcData.pc_offline_long.length, 'Rechner lange offline', _vm.rpcData.pc_offline_long)}}},[_vm._v(" Rechner lange offline "),_c('span',[_vm._v(_vm._s(_vm.rpcData.pc_offline_long.length))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.rpcData.virus_count > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                    'text-white', 'mb-2'],on:{"click":function($event){return _vm.onModalShow(_vm.rpcData.virus_count, 'Virenwarnungen', _vm.rpcData.pc_viruses)}}},[_vm._v(" Virenwarnungen "),_c('span',[_vm._v(_vm._s(_vm.rpcData.virus_count))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card header-bg-variant="alfa-blue" header="Team" header-class="text-center header-font-size">
    <b-row cols="2">
      <b-col>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center list-font-size mb-2 list-default">
            Rechner insgesamt
            <span>{{ pcCount }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      licenseWarningCount > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                      'text-white', 'mb-2', ]"
                             @click="onModalShow('Lizenzwarnungen - Team', licenseWarningCount, 'license')">
            Lizenzwarnungen
            <span>{{ licenseWarningCount }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center list-font-size mb-2 list-default">
            Rechner online
            <span>{{ pcOnlineCount }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      processCount > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                      'text-white', 'mb-2', ]"
                             @click="onModalShow('Prozesswarnungen - Team', processCount, 'process')">
            Prozesswarnungen
            <span>{{ processCount }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size', 'mb-2',
                                      pcOfflineLong > 0 ? ['list-warning', 'clickable'] : 'list-success']"
                             @click="onModalShow('Rechner lange offline - Team', pcOfflineLong, 'longOffline')">
            Rechner lange offline
            <span>{{ pcOfflineLong }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      virusCount > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                      'text-white', 'mb-2', ]"
                             @click="onModalShow('Virenwarnungen - Team', virusCount, 'virus')">
            Virenwarnungen
            <span>{{ virusCount }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <SiteModal id="siteModal" :name="modalName" :type="type" :siteList="teamData"/>
  </b-card>
</template>

<script>
import _ from "lodash";
import SiteModal from "@/components/dashboard/dashboardModal/SiteModal";

export default {
  name: "TeamInfo",
  props: ['teamData'],
  components: {
    SiteModal
  },
  data() {
    return {
      modalName: '',
      type: ''
    }
  },
  methods: {
    onModalShow(name, count, type) {
      if(count === 0) {
        return;
      }
      this.modalName = name
      this.type = type
      this.$bvModal.show('siteModal')
    }
  },
  computed: {
    pcCount() {
      let x = 0
      _.forEach(this.teamData, (site) => {
        x = x + site.pc_count
      })
      return x
    },
    pcOnlineCount() {
      let x = 0
      _.forEach(this.teamData, (site) => {
        x = x + site.pc_count_online
      })
      return x
    },
    licenseWarningCount() {
      let x = 0
      _.forEach(this.teamData, (site) => {
        x = x + site.license_warning_count
      })
      return x
    },
    processCount() {
      let x = 0
      _.forEach(this.teamData, (site) => {
        x = x + site.process_count
      })
      return x
    },
    virusCount() {
      let x = 0
      _.forEach(this.teamData, (site) => {
        x = x + site.virus_count
      })
      return x
    },
    pcOfflineLong() {
      let x = 0
      _.forEach(this.teamData, (site) => {
        x = x + site.pc_offline_long.length
      })
      return x
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Dashboard.scss';
</style>

<template>
  <b-card :title="news.headline" :title-tag="'h5'" :body-class="['news-card', borderVariant]">
    <b-card-sub-title :sub-title-tag="'h5'" class="mb-2">
      <b-badge v-if="news.news_category" variant="primary" class="badge-outlined">
        {{ news.news_category.displayname }}
      </b-badge>
    </b-card-sub-title>
    <VueMarkdownRender :source="news.message" :class="renderClasses"/>
    <div class="text-secondary" style="font-size: 14px">Erstellt am: {{ news.created_at | datetime }}</div>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import VueMarkdownRender from "vue-markdown-render";

export default {
  name: "NewsSingle",
  props: ['news'],
  components: {
    VueMarkdownRender
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    renderClasses() {
      if(this.userThemeLight) { return 'text-dark' }
      return 'text-white'
    },
    borderVariant() {
      if(this.news.news_priority_id === 1) {
        return 'news-card-primary'
      }
      else if(this.news.news_priority_id === 2) {
        return 'news-card-warning'
      }
      else if(this.news.news_priority_id === 3) {
        return 'news-card-danger'
      }
      else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/Badges.scss";
@import "../../assets/styles/News.scss";
</style>

<template>
  <b-jumbotron class="jumbotron-background">
    <template #header>Dashboard</template>
    <h3 v-if="dashboardInfo" class="text-bold">{{ dashboardInfo.site.site.displayname }}</h3>
    <h4 v-if="dashboardInfo">{{ dashboardInfo.site.site.ip_subnet }}</h4>
    <h4 v-if="dashboardInfo">{{ dashboardInfo.site.site.service_version.version }}
      <b-icon-info-circle-fill class="ml-2" :id="'tt-info-' + dashboardInfo.site.site.id"/>
      <b-tooltip :target="'tt-info-' + dashboardInfo.site.site.id" triggers="hover">Dienstversionsnummer</b-tooltip>
    </h4>
    <hr>
    <h6 v-if="timestamp">Aktualisierungszeitpunkt: {{ timestamp | datetime }}</h6>
  </b-jumbotron>
</template>

<script>
export default {
  name: "DashboardHeader",
  props: ['dashboardInfo', 'timestamp']
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Dashboard.scss';
</style>

<template>
  <b-card header-bg-variant="alfa-blue" header="Standort" header-class="text-center header-font-size">
    <b-row cols="2">
      <b-col>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center list-font-size mb-2 list-default">
            Rechner insgesamt
            <span>{{ siteData.pc_count }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      siteData.license_warning_count > 0 ? 'list-danger' : 'list-success',
                                      'text-white', 'mb-2']"
                             :to="{ name: 'Licenses', query: {site: siteData.site.shortname}}">
            Lizenzwarnungen
            <span>{{ siteData.license_warning_count }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center list-font-size mb-2 list-default">
            Rechner online
            <span>{{ siteData.pc_count_online }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      siteData.process_count > 0 ? 'list-danger' : 'list-success',
                                      'text-white', 'mb-2']"
                             :to="{ name: 'ProcessList', query: {site: siteData.site.shortname}}">
            Prozesswarnungen
            <span>{{ siteData.process_count }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size', 'mb-2',
                                      siteData.pc_offline_long.length > 0 ? ['list-warning', 'clickable'] : 'list-success']"
                             @click="onModalShow(siteData.pc_offline_long.length)">
            Rechner lange offline
            <span>{{ siteData.pc_offline_long.length }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      siteData.virus_count > 0 ? 'list-danger' : 'list-success',
                                      'text-white', 'mb-2']"
                             :to="{ name: 'VirusList', query: {site: siteData.site.shortname}}">
            Virenwarnungen
            <span>{{ siteData.virus_count }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "SiteInfo",
  props: ['siteData'],
  methods: {
    onModalShow(count) {
      if(count === 0) {
        return;
      }
      let data = {
        title: 'Rechner lange offline',
        pcList: this.siteData.pc_offline_long
      }
      this.$emit('openPcModal', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Dashboard.scss';
</style>

<template>
  <b-modal id="pcModal" :title="title" ok-only ok-title="Schließen" size="xl" content-class="mt-5" scrollable>
    <b-row cols="4">
      <b-col v-for="pc in pcList" :key="pc">
        <b-list-group class="mb-2">
          <b-list-group-item :class="style(pc)" @click="goToSingleDisplay(pc)">
            {{ pc.hostname }}
            <span v-if="pc.count !== undefined" class="text-right">{{ pc.count }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "PcModal",
  props: ['title', 'pcList'],
  methods: {
    style(pc) {
      let x = ['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size', 'mb-2', 'clickable']
      if(pc.count !== undefined) {
        pc.count > 0 ? x.push('list-danger', 'text-white') : x.push('list-success', 'text-white')
      }
      else {
        x.push('list-danger', 'text-white')
      }
      return x
    },
    goToSingleDisplay(pc) {
      let hostname = pc.hostname
      this.$router.push({name: 'Computer', params: { hostname }, query: {id: pc.id}})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/Dashboard';
</style>

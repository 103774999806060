var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"header-bg-variant":"alfa-blue","header":"Team","header-class":"text-center header-font-size"}},[_c('b-row',{attrs:{"cols":"2"}},[_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center list-font-size mb-2 list-default"},[_vm._v(" Rechner insgesamt "),_c('span',[_vm._v(_vm._s(_vm.pcCount))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.licenseWarningCount > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                    'text-white', 'mb-2' ],on:{"click":function($event){return _vm.onModalShow('Lizenzwarnungen - Team', _vm.licenseWarningCount, 'license')}}},[_vm._v(" Lizenzwarnungen "),_c('span',[_vm._v(_vm._s(_vm.licenseWarningCount))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{staticClass:"d-flex justify-content-between align-items-center list-font-size mb-2 list-default"},[_vm._v(" Rechner online "),_c('span',[_vm._v(_vm._s(_vm.pcOnlineCount))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.processCount > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                    'text-white', 'mb-2' ],on:{"click":function($event){return _vm.onModalShow('Prozesswarnungen - Team', _vm.processCount, 'process')}}},[_vm._v(" Prozesswarnungen "),_c('span',[_vm._v(_vm._s(_vm.processCount))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size', 'mb-2',
                                    _vm.pcOfflineLong > 0 ? ['list-warning', 'clickable'] : 'list-success'],on:{"click":function($event){return _vm.onModalShow('Rechner lange offline - Team', _vm.pcOfflineLong, 'longOffline')}}},[_vm._v(" Rechner lange offline "),_c('span',[_vm._v(_vm._s(_vm.pcOfflineLong))])])],1)],1),_c('b-col',[_c('b-list-group',[_c('b-list-group-item',{class:['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                    _vm.virusCount > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                    'text-white', 'mb-2' ],on:{"click":function($event){return _vm.onModalShow('Virenwarnungen - Team', _vm.virusCount, 'virus')}}},[_vm._v(" Virenwarnungen "),_c('span',[_vm._v(_vm._s(_vm.virusCount))])])],1)],1)],1),_c('SiteModal',{attrs:{"id":"siteModal","name":_vm.modalName,"type":_vm.type,"siteList":_vm.teamData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card header-bg-variant="alfa-blue" header-class="text-center header-font-size">
    <template #header>
      <div>RPCs <b-icon-info-circle scale="0.8" id="tt-card-header-rpc"/></div>
      <b-tooltip target="tt-card-header-rpc">Zugewiesene RPCs, von Kunden aus dem eigenen Team</b-tooltip>
    </template>
    <b-row cols="2">
      <b-col>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center list-font-size mb-2 list-default">
            Rechner insgesamt
            <span>{{ rpcData.pc_count }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      rpcData.pc_license_warning.length > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                      'text-white', 'mb-2']"
                             @click="onModalShow(rpcData.pc_license_warning.length, 'Lizenzwarnungen', rpcData.pc_license_warning)">
            Lizenzwarnungen
            <span>{{ rpcData.pc_license_warning.length }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center list-font-size mb-2 list-default">
            Rechner online
            <span>{{ rpcData.pc_count_online }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      rpcData.process_count > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                      'text-white', 'mb-2']"
                             @click="onModalShow(rpcData.process_count, 'Prozesswarnungen', rpcData.pc_processes)">
            Prozesswarnungen
            <span>{{ rpcData.process_count }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size', 'mb-2',
                                      rpcData.pc_offline_long.length > 0 ? ['list-warning', 'clickable'] : 'list-success']"
                             @click="onModalShow(rpcData.pc_offline_long.length, 'Rechner lange offline', rpcData.pc_offline_long)">
            Rechner lange offline
            <span>{{ rpcData.pc_offline_long.length }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item :class="['d-flex', 'justify-content-between', 'align-items-center', 'list-font-size',
                                      rpcData.virus_count > 0 ? ['list-danger', 'clickable'] : 'list-success',
                                      'text-white', 'mb-2']"
                             @click="onModalShow(rpcData.virus_count, 'Virenwarnungen', rpcData.pc_viruses)">
            Virenwarnungen
            <span>{{ rpcData.virus_count }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "RpcInfo",
  props: ['rpcData'],
  methods: {
    onModalShow(count, title, datalist) {
      if(count === 0) {
        return;
      }
      let data = {
        title: title,
        pcList: datalist
      }
      this.$emit('openPcModal', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Dashboard.scss';
</style>
